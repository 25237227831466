<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      우리 제품 판매 현황<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section">
      <!---->
      <div class="rn_ms_box_7">
        <!---->
        <div class="sect_tit">
          <h5>{{ myInfo.provider_name }}님 제품 판매 현황</h5>
          <select name="" v-model="range" @change="setRange($event)">
            <option value="today">오늘</option>
            <option value="week">이번 주</option>
            <option value="month">이번 달</option>
            <option value="year">올해</option>
            <option value="all">전체</option>
          </select>
        </div>
        <!-- -->
        <ReviewGoodsCategory
          v-if="categories && categoryInfo"
          :category="categories"
          :id="!cateId ? categoryInfo[0].id : cateId"
          ref="goodsCategory"
          @getGoodsCategory="getGoodsCategory"
        />
        <!-- -->
        <div class="sect_sort" v-if="listArray.length > 0">
          <select name="" v-model="sort" @change="setSort($event)">
            <option value="reg_dt">등록순</option>
            <option value="sale">총 판매량순</option>
            <option value="refund">반품수순</option>
          </select>
        </div>
        <div class="sect_list" v-if="listArray.length > 0">
          <div class="box" v-for="(item, index) in listArray" :key="index">
            <h4>{{ item.goods_name }}</h4>
            <ul>
              <li>총 판매 {{ item.total }}건</li>
              <li>반품 {{ item.refund }}건</li>
              <li>취소 {{ item.cancel }}건</li>
            </ul>
          </div>
        </div>
        <div class="sect_list none_data" v-else>
          <p>검색된 판매 정보가 없습니다.</p>
        </div>
        <!---->
        <div class="rn_more" v-if="totalPage > page">
          <router-link to="" @click.native="getMySaleList()"
            >{{ limit }}개 더보기<img
              src="@/assets/images/sub/my_down_ico.png"
              alt=""
          /></router-link>
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
import ReviewGoodsCategory from "@/components/review/state/ReviewGoodsCategory";
export default {
  data() {
    return {
      cateIdTmp: null,
      category_code: this.$route.query.category_code || null,
      pageName: "/mypage/my_goods_sale_list",
      sort: "reg_dt",
      range: "today",
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
      page: 0,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });

    this.getMainMenu();
    this.getCategories();
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("shop", ["categories"]),
    ...mapState("mypage", [
      "myInfo",
      "result",
      "msg",
      "listArray",
      "totalPage",
      "limit",
    ]),
    cateId: {
      get() {
        if (this.category_code === null) {
          //최초에만 대표 카테고리를 부여함.
          this.cateIdTmp = parseInt(this.myInfo.cate_id);
          //   this.cateIdTmp = 54;
        }
        return this.cateIdTmp;
      },
      set(value) {
        // console.log(this.cateIdTmp);
        this.cateIdTmp = value;
      },
    },
    categoryInfo() {
      if (this.categories.length > 0) {
        if (this.category_code === null) {
          return [
            {
              index: 0,
              id: parseInt(this.categories[0].id),
              category_code: this.categories[0].category_code,
              title: this.categories[0].title,
              des: "카테고리코드가 없을떄",
            },
          ];
        }

        let index = this.categories.findIndex(
          (cate) => cate.category_code === this.category_code
        );

        if (index >= 0) {
          return [
            {
              index: index,
              id: parseInt(this.categories[0].id),
              category_code: this.categories[index].category_code,
              title: this.categories[index].title,
              des: "인덱스가 있을때",
            },
          ];
        }
      }
      return [
        {
          index: 0,
          id: null,
          category_code: process.env.VUE_APP_DEFAULT_GOOD, //상품의 기본 기초코드
          title: null,
          des: "널 일때 ",
        },
      ];
    },
    nowUrl() {
      return `${this.pageName}?category_code=${this.category_code}`;
    },
  },
  methods: {
    setRange(event) {
      this.range = event.target.value;
      this.getMySaleList(true);
    },
    setSort(event) {
      this.sort = event.target.value;
      this.getMySaleList(true);
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      this.$route.path.split("/").map((url) => {
        switch (url) {
          case "goods_main":
          case "seller_main":
            this.$refs.reviewMainMenu.goSlide(this.returnIndex(1));
            break;
          case "news_main":
            this.$refs.reviewMainMenu.goSlide(this.returnIndex(3));
            break;
          default:
        }
      });
    },
    async getCategories() {
      // console.log(this.category_code, 2);
      await this.$store.dispatch("shop/getCategories");
      // console.log("this.cateId", this.cateId);
      if (!this.cateId) {
        this.$refs.goodsCategory.reflash(this.categoryInfo[0].index);
      } else {
        const cateIndex = this.categories.findIndex(
          (cate) => parseInt(cate.id) === parseInt(this.cateId)
        );
        // console.log(cateIndex);
        this.$refs.goodsCategory.reflash(cateIndex);
        this.category_code = this.categories[cateIndex].category_code;
      }
      this.category_code = !this.category_code
        ? this.categoryInfo[0].category_code
        : this.category_code;
      // console.log(this.category_code, 3);

      this.getMySaleList(true);
    },
    async getMySaleList(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("mypage/getMySaleList", {
        provider_seq: this.myInfo.provider_seq,
        category_code: this.category_code,
        page: this.page,
        sort: this.sort,
        range: this.range,
        reset,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
      }
      this.page++;
    },
    getGoodsCategory(id) {
      if (parseInt(this.cateIdTmp) !== parseInt(id)) {
        this.cateId = parseInt(id);
        const category = this.categories.filter(
          (cate) => parseInt(cate.id) === parseInt(id)
        );
        this.category_code = category[0].category_code;
        this.range = "today";
        this.sort = "reg_dt";
        this.goPage();
        this.getMySaleList(true);
      }
    },
    goPage() {
      if (this.$route.fullPath === this.nowUrl) return false;
      this.$router.push(this.nowUrl).catch(() => {});
    },
    getMenu(menu) {},
  },
  components: {
    MainMenu,
    ReviewGoodsCategory,
  },
};
</script>

<style lang="scss">
.cate_r_slide {
  width: 100%;
  overflow: hidden;
  background: #eee;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  .slide_cate {
    width: calc(100% - 30px);
    float: left;
    .slide_box {
      width: 100%;
      overflow: hidden;
    }
  }
  .left_slide {
    font-weight: 600;
    width: 15px;
    line-height: 40px;
    text-align: center;
    float: left;
  }
  .right_slide {
    font-weight: 600;
    width: 15px;
    line-height: 40px;
    text-align: center;
    float: left;
  }
}
.sect_list {
  &.none_data {
    text-align: center !important;
    padding: 20px 0 0 !important;
  }
}
</style>
